<template>
<div class="progress">
  <div class="progress-bar bg-warning" role="progressbar"
   :style="{'width': value ? value+'%': '20%'}"></div>
   <!-- 20 = 1/5 -->
</div>
</template>

<script>
    export default {
     name:'linearProgressBar',
     props:['value'],
    }
</script>

<style lang="scss" scoped>
.progress{
height:0.5rem}

</style>