<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <h3 class="text-center primary-color almost-done">
              {{ $t("signup.almostDone") }}
            </h3>
            <button
              type="button"
              class="btn-close"
              @click="$emit('update', false)"
            ></button>
          </div>
          <p class="text-center light-gray-text">
            {{
              $t("signup.createAnAccountToViewYourMatchesAndStartYourProject")
            }}
          </p>

          <div class="pt-0 border-0 modal-body">
            <div class="row justify-content-center">
              <CLientSignup :isAlMostDone="true" class="isFrom-client-wizard" @closeSignup="close" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CLientSignup from "../../pages/signup/client.vue";
import "./style.scss";
import "./wizard-client.css";
export default {
  name: "signupModal",
  components: { CLientSignup },
  props: ["isOpen"],
  methods: {
    close() {
      this.$emit("update", false);
    },
  },
};
</script>