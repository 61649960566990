<template>
  <div class="client-wizard-form">
    <!-- main-steps-body -->
    <div>
      <div class>
        <div class="">
          <div v-for="(step, i) in stepper" :key="step.stepNum">
            <component
              v-if="currentStep == stepper[i].stepNum"
              :stepNum="stepper[i].stepNum"
              :is="stepper[i].content"
            ></component>
          </div>
        </div>
      </div>
    </div>
    <div></div>

    <!-- ./main-steps-body -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AboutProject from "../../../components/AboutProject/index.vue";
import ClientSteps from "../../../enums/ClientWizzardSteps";
import SelectServices from "../../../components/SelectServices/index.vue";
import Technologies from "../../../components/Technologies/index.vue";
import Details from "../../../components/Details/index.vue";
import Booking from "../../../components/Booking/index.vue";
import "./style.scss";

export default {
  name: "Wizard",
  components: {
    AboutProject,
    SelectServices,
    Technologies,
    Details,
    Booking,
  },
  data() {
    return {
      ProfileStep: ClientSteps.PROFILE,
      stepper: [
        {
          stepNum: ClientSteps.ABOUT_PROJECT,
          title: "About Project",
          content: AboutProject,
        },
        {
          stepNum: ClientSteps.SELECT_SERVICE,
          title: "Select Service",
          content: SelectServices,
        },
        {
          stepNum: ClientSteps.TECHNOLOGIES,
          title: "Technologies",
          content: Technologies,
        },
        {
          stepNum: ClientSteps.DETAILS,
          title: "Details",
          content: Details,
        },
        {
          stepNum: ClientSteps.BOOK_MEETING,
          title: "Booking Meeting",
          content: Booking,
        },
      ],
      hasError: true,
    };
  },
  computed: {
    ...mapGetters({
      currentStep: "auth/getUserStep",
      user: "auth/getUser",
    }),
  },
  watch: {
    currentStep: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue == 6) {
          this.handleMatchingRedirection();
        }
      },
    },
  },
  methods: {
    handleMatchingRedirection() {
      this.$store.dispatch("auth/setBookMeeting", false);



      let isMeetingReserved = JSON.parse(
        localStorage.getItem("meeting_reserved")
      );

      if (isMeetingReserved) {
        //-----------live-config-----------
/*         if (process.env.VUE_APP_AWS_BASE_URL.includes("production")) {
          this.twitterEvent();
          this.snapChatEvent();
        } */
        //-----------------------------------
        window.gtag("event", "client booked meeting", {
          user_type: "Client",
          step_number: 7,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
        });

        this.$router.push({
          name: "clientMatching",
          query: {
            meeting_reserved: "1",
          },
        });
      } else {
        this.$router.push({ name: "clientMatching" });
      }
    },
    log(value) {
      return console.log(value);
    },
    twitterEvent() {
      // Insert Twitter Event ID
      // eslint-disable-next-line no-undef
      if(!twq) return;
      // eslint-disable-next-line no-undef
      twq("event", "tw-odhvn-oednh", {
        conversion_id: this.user.id, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        email_address: this.user.email, // use this to pass a user’s email address
        phone_number: null, // phone number in E164 standard
      });
    },
    snapChatEvent() {
      // eslint-disable-next-line no-undef
      if(!snaptr) return 
      // eslint-disable-next-line no-undef
      snaptr("track", "SIGN_UP", {
        conversion_id: this.user.id,
        email_address: this.user.email.toLowerCase(),
      });
    },
  },
};
</script>

<style scoped>
#stepper-items {
  padding: 10px 100px;
}
</style>