<template>
  <div>
    <ClientWizardContainer
      :step="stepNum"
      :hasError="validForm"
      :formData="formData"
      @runValidForm="runValidForm"
    > 
      <div class="container" id="stepper-items" slot="form">
        <form id="step1">
          <h2 class="mt-4 mb-5 font-weight-bold">
            {{ $t("clientWizard.tellUsAboutYourProduct") }}
          </h2>
            

          <div class="row">
            <div class="mb-4 col-sm-6 col-12">
              <label class="mb-1 form-label">
                {{ $t("clientWizard.enterYourProductsName") }}</label
              >
              <input
                :placeholder="$t('clientWizard.productsName')"
                class="px-2 w-100 form-control"
                v-model.trim="$v.formData.name.$model"
                type="text"
              />
              <!-- ********* Validation Error*********** -->
              <VulidateError
                :validationField="$v.formData.name"
                :params="['alphaNumericWithSpecialChars', 'alphaNumericWithSpecialCharsProjectName', { maxLength: { number: '50' } }]"
              />
              <!-- ************************************* -->
            </div>
            <div class="mb-4 col-sm-6 col-12">
              <label class="mb-1 form-label required">{{
                $t("clientWizard.chooseIndustry")
              }}</label>
              <v-select
                v-model="$v.formData.project_type_id.$model"
                :reduce="(name) => name.id"
                class="style-chooser"
                :placeholder="$t('clientWizard.chooseIndustry')"
                :options="industryList"
                label="name"
              >
                <div slot="no-options">
                  {{ $t("talent.sorryNoMatchingOptions") }}
                </div>
              </v-select>
              <!-- ********* Validation Error*********** -->
              <div class="error-select-validation-message">
                <VulidateError
                  :validationField="$v.formData.project_type_id"
                  :params="['required']"
                />
              </div>
              <!-- ************************************* -->
            </div>
          </div>
          <div class="mb-4 col-sm-12">
            <label class="form-label">
              {{ $t("clientWizard.tellUsAboutYourProduct") }}
            </label>
            <textarea
              v-model.trim="$v.formData.description.$model"
              class="form-control"
              :placeholder="$t('clientWizard.typeYourProjectsScope')"
            ></textarea>
            <!-- ********* Validation Error*********** -->
            <div class="mt-1">
              <VulidateError
                :validationField="$v.formData.description"
                :params="[{ maxLength: { number: '500' } }]"
              />
            </div>
            <!-- ************************************* -->
          </div>
        </form>
      </div>
    </ClientWizardContainer>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import VulidateError from "../../components/shared/VulidateError/vulidateError.vue";
import ClientWizardContainer from "../ClientWizzardContainer/index.vue";
import "vue-select/dist/vue-select.css";
import "./style.scss";
import { alphaNumericWithSpecialChars, alphaNumericWithSpecialCharsProjectName } from "../../utils/utils";

export default {
  components: { VulidateError, ClientWizardContainer },
  props: ["stepNum"],
  computed: {
     validForm() {
      return this.$v.$invalid;
    },
  },
  data: function () {
    return {
      industryList: [],
      formData: {
        name: "",
        project_type_id: "",
        description: "",
      },
    };
  },
  validations: {
    formData: {
      name: {
        maxLength: maxLength(50),
        alphaNumericWithSpecialChars,
        alphaNumericWithSpecialCharsProjectName
      },
      project_type_id: {
        required,
      },
      description: {
        maxLength: maxLength(500),
      },
    },
  },
  mounted() {
    this.getIndustryList().then(() => this.getAboutData());
    // this.getAboutData();
  },
  methods: {
    runValidForm() { 
       setTimeout(() => {
          this.$v.$touch();
        });
    },
    async getIndustryList() {
      try {
        await this.axios.get("/api/staticContent/projectTypes").then((res) => {
          this.industryList = res.data.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getAboutData() {
      if (this.$store.state.auth.token) {
        try {
          await this.axios.get("/api/client/projects").then((res) => {
            let data = res.data.data;
            this.formData.name = data.name;
            this.formData.project_type_id = data.project_type.id || "";
            this.formData.description = data.description;
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        if (
          Object.keys(this.$store.getters["client/getStepOneData"]).length > 0
        ) {
          let data = this.$store.getters["client/getStepOneData"];
          this.formData.name = data.name;
          this.formData.project_type_id = data.project_type_id;
          this.formData.description = data.description;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
